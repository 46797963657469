@import "../../../../base";

.project {
  .single-element-tab-content.details-content {
    .inline-edit-block {
      max-width: 630px;
  
      .project-icon-name-wrapper {
        display: flex;
        align-items: center;
  
        .project-item-icon-wrapper {
          width: 35px;
          height: 35px;
          border-radius: 35px;;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 17px;
    
          i {
            font-size: 18px;
          }
        }
  
        .progress-bar-wrapper {
          width: 54px;
          height: 4px;
          background-color: #F2F4F6;
          display: flex;
          margin-left: 9px;
        }
      }
    }
  
    .project-implemented-infos {
      margin-top: 33px;
      width: 300px;
      justify-content: flex-start;
  
      .implemented-info {
        div, p {
          text-align: left;
        }
  
        & + .implemented-info {
          margin-left: 39px;
        }
      }
    }
  }
}

