@import url("../../../_base.scss");

.stages-circle-diagram {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 0;
  /* justify-items: center; */
  max-width: 1101px;
  width: 100%;
  margin: 90px auto 0;
  // padding-right: 40px;

  .diagram-wrapper {
    svg {
      path {
        cursor: pointer;

        &.stage-arrow-bc {
          fill: rgba(16, 110, 168, 0.3);

          &.hover {
            fill: rgba(16, 110, 168, 0.4);
          }

          &.active {
            fill: rgba(16, 110, 168, 1);
          }
        }
      }

      circle {
        opacity: 0;
        cursor: pointer;

        &.active {
          opacity: 1;
        }
      }
    }
  }

  .legends {
    height: 422px;
    overflow: hidden;
    position: relative;

    .legend {
      padding: 32px 32px 14px;
      background-color: white;
      opacity: 0.3;
      transition: 300ms all;

      header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          line-height: 34px;
          color: #372c15;
          // flex: 1 0 0;
          width: 70%;
        }

        a.chevron-link {
          margin: 0 0 0 25px;
        }
      }

      .legend-collapse {
        transition: height 0.3s;
        padding-top: 18px;

        .legend-content {
          max-height: 110px;
          overflow-y: auto;

          p {
            font-family: "Satoshi";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.8);
            margin: 0;

            & + p {
              margin-top: 18px;
            }
          }
        }
      }

      &:not(.active) {
        cursor: pointer;
      }

      &.active {
        opacity: 1;
      }

      & + .legend {
        margin-top: 13px;
      }
    }

    &:after {
      width: 100%;
      height: 40px;
      background: linear-gradient(
        to bottom,
        transparent,
        rgba(246, 246, 246, 1)
      );
      content: " ";
      display: block;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }
}
