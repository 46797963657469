@import "../../../_base.scss";

.collapse-trigger {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .collapse-trigger-arrow {
        color: #778CA2;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 18px;
        text-align: center;
        transition: 300ms all;
        margin-right: 5px;
        position: relative;
        transform: rotateX(180deg) translateY(2px);
    }

    &.opened {
        .collapse-trigger-arrow {
            transform: none;
        }
    }

    &.arrow-on-right {
        .collapse-trigger-arrow {
            margin-left: 5px;
        }
    }
}