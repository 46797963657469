@import "../../../base";

.main-header {
  flex: 1;
  border-left: none;
  border-bottom: 1px solid #dee2e6;
  border-radius: 0;
  padding: 0 70px;
  height: 130px;

  .p-toolbar-group-left {
    a.logo {
      font-family: "Satoshi", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 39.4228px;
      line-height: 106%;
      color: #046aa4;
      text-decoration: none;

      i {
        font-family: "Font Awesome 6 Duotone";
        font-style: normal;
        font-weight: 900;
        font-size: 39.4228px;
        line-height: 106%;
        margin-right: 10px;
        --fa-primary-color: #8ab9da;
        --fa-secondary-color: #0a6aae;
        --fa-secondary-opacity: 1;
      }
    }
  }

  .p-toolbar-group-right {
    a {
      font-family: "Satoshi", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #000000;
      text-decoration: none;

      & + a {
        margin-left: 32px;
      }

      &.active {
        color: #0a6aae;
      }

      &:hover {
        color: #106fa8;
      }
    }
  }
}
