@import "../../../base";

.project-recommendation-list {
  .p-scrollpanel {
    background-color: white;
  }

  .mechanism {
    margin-top: 20px;

    .mechanism-title {
      color: #778ca2;
      font-family: "Satoshi", sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 8px;
    }

    .mechanism-date {
      .date-header {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        cursor: pointer;
        margin-bottom: 10px;

        .collapse-trigger-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;

          .date-title {
            color: #252631;
            font-family: "Satoshi", sans-serif;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 22px;
            //flex: 1; // occupy the rest

            i {
              margin-left: 5px;
              color: #106fa8;
              font-size: 13px;
            }
          }

          a.add-all {
            color: #106fa8;
            font-family: "Satoshi", sans-serif;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-transform: uppercase;

            i {
              color: #106fa8;
              font-size: 18px;
              letter-spacing: 0;
              line-height: 21px;
              margin-right: 4px;
            }
          }

          .recommendation-number {
            color: #252631;
            font-family: "Satoshi", sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
            margin-left: 30px;

            i {
              color: #252631;
              font-size: 18px;
              letter-spacing: 0;
              line-height: 21px;
              text-align: center;
              margin-right: 7px;
            }
          }
        }

        i {
          margin-left: 20px;
        }
      }
      .date-content {
        transition: 300ms all;
        height: auto;
        overflow: hidden;

        .recommendation {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          padding-left: 15px;
          padding-bottom: 15px;

          .nrtd-list-item--content {
            &.clickable {
              cursor: pointer;

              &:hover {
                title {
                  color: #106fa8;
                }
              }
            }

            title.clickable {
              cursor: pointer;

              &:hover {
                color: #106fa8;
              }
            }
          }

          .nrtd-list-item--metadata {
            width: 80%;
            margin: 5px 0 0;

            .metadata-column {
              width: 33.33%;
              flex: 1 1;
            }

            .collapse-trigger {
              width: 100%;

              .collapse-trigger-content {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                title {
                  margin-bottom: 0;
                  color: #98a9bc;
                  font-family: "Satoshi", sans-serif;
                  font-size: 14px;
                  letter-spacing: 0;
                  line-height: 21px;
                  font-weight: 400;
                }
              }

              i.collapse-trigger-arrow {
                position: relative;
                top: 2px;
              }
            }

            .nrtd-list-item--metadata-values {
              transition: 300ms all;
              margin-top: 5px;
              width: 100%;

              & > div {
                display: flex;
                justify-content: space-between;
                width: 100%;

                ul {
                  margin-bottom: 0;
                  margin-top: 0;
                  padding-left: 27px;

                  li {
                    color: #252631;
                    font-family: "Satoshi", sans-serif;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 17px;
                  }
                }
              }
            }
          }

          .property-field {
            width: 30%;
            max-width: 250px;

            & + .property-field {
              margin-left: 15px;
            }
          }

          &:first-child {
            padding-top: 15px;
          }

          &:last-child {
            padding-bottom: 10px;
            border-bottom: 0;
          }
        }
      }
    }

    & {
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
}

.filter-panel + .project-recommendation-list {
  margin-top: 16px;
}
