@import "../../base";

.page-wrapper.mechanisms {
  .mechanism {
    margin-top: 60px;

    header {
      height: 384px;
      position: relative;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      &:after {
        display: block;
        content: " ";
        width: 80%;
        height: 32px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #f6f6f6;
      }
    }

    h2 {
      font-family: "Satoshi";
      font-style: normal;
      font-weight: 700;
      font-size: 41px;
      line-height: 120%;
      color: #1b1a1c;
      margin: 0 0 20px;
      width: 70%;
    }

    p {
      margin: 0;
      font-family: "Satoshi";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.8);
      width: 70%;

      & + p {
        margin-top: 25px;
      }
    }

    .blue-button {
      margin-top: 26px;
    }

    .treaties {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 14px;

      .treaty {
        box-shadow: 0px 4px 50px rgba(111, 109, 109, 0.2);
        background-color: white;

        .treaty-img {
          height: 275px;
        }

        .treaty-content {
          padding: 25px;

          h3 {
            font-family: "Satoshi";
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 34px;
            color: #372c15;
            margin: 0 0 12px 0;
          }

          p {
            font-family: "Satoshi";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.8);
            margin: 0;
            width: unset;

            & + p {
              margin-top: 18px;
            }
          }

          a.blue-button {
            margin-top: 18px;
          }
        }
      }
    }
  }
}
