@import "./base";

// layout
body {
  background-color: #f6f6f6;
  font-family: "Satoshi", sans-serif;
}
.container {
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.page-wrapper {
  padding-bottom: 100px;
}
.mechanism-page {
  .mechanism-columns {
    margin-left: auto;
    margin-right: auto;
    max-width: 1170px;
    display: grid;
    grid-template-columns: 1fr 0.45fr;
    column-gap: 90px;
    margin-top: 60px;
    position: relative;
    left: 3%;
    align-items: start;

    .mechanism-content {
      p {
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.8);

        & + p {
          margin-top: 20px;
        }
      }

      ul {
        li {
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      p + ul {
        margin-top: 20px;
      }

      h2 {
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 700;
        font-size: 41px;
        line-height: 120%;
        color: #1b1a1c;
        margin: 50px 0 30px;
      }

      h3 {
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        color: #106fa8;
        margin: 30px 0 20px;
      }

      svg {
        background-color: gainsboro;

        path {
          fill: rgba(16, 111, 168, 0.302);

          &:hover {
            fill: rgba(16, 111, 168, 1);
          }
        }

        circle {
          fill: transparent;
        }
      }
    }

    .mechanism-side-panel {
      position: relative;
      top: -90px;
      background-color: white;
      box-shadow: 0px 4px 50px rgba(111, 109, 109, 0.2);
      border-radius: 5px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .side-panel-info {
        width: 100%;

        label {
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 149%;
          color: #0a6aae;

          &.orange {
            color: #ffab2b;
          }
        }

        .value {
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.8);

          a {
            margin-left: 8px;

            i {
              font-size: 17px;
              line-height: 150%;
              color: #0a6aae;
            }
          }

          &.smaller {
            font-size: 15px;
            line-height: 149%;
          }
        }

        &.multiple {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }

        & + .side-panel-info {
          border-top: 2px solid #ebeaea;
          margin-top: 12px;
          padding-top: 12px;
        }
      }

      a.blue-button {
        margin: 16px 0 0;
      }

      .p-chart {
        margin: 10px 0 25px;
      }
    }

    @media (max-width: 1380px) {
      left: unset;
      position: static;
    }
  }
}

// general objects
a {
  cursor: pointer;
  text-decoration: none;

  &.reset-link {
    width: 38px;
    font-family: "Satoshi", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    color: #fe4d55;
  }
}
p {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.8);

  a {
    font-weight: bold;
    color: $primaryBlue;
  }
}
h2 {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 41px;
  line-height: 120%;
  color: #1b1a1c;
  margin: 20px 0;
}
h3 {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  color: #372c15;
}

// general classes
.blue-button {
  padding: 19px 40px;
  background-color: $primaryBlue;
  color: white;
  border-radius: 100px;
  border: none;
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  transition: all 300ms;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;

  i {
    font-size: 15px;
    transition: transform 300ms;
    transform: translateY(-3px);
    margin-left: 7px;
    position: relative;
    top: 4px;
  }

  &:hover {
    background-color: $darkBlue;
    transform: translateY(-3px);

    i {
      transform: translate(3px, -5px);
    }
  }
}
.chevron-link {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #106fa8;
  border-radius: 50%;
  margin-top: 13px;
  cursor: pointer;
  transition: 400ms all;

  i {
    font-size: 13px;
    line-height: 13px;
    color: white;
  }

  &:hover {
    background-color: $darkBlue;
    transform: scale(1.25);
  }
}
.narrow-section {
  max-width: 1080px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

// style overwrites for primes
.p-datatable {
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 50px rgba(111, 109, 109, 0.2);

  table {
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    thead.p-datatable-thead {
      background-color: #f2f4f6;

      & > tr {
        & > th {
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          padding: 25px 30px;
          color: #0a6aae;

          .p-sortable-column-icon {
            color: #0a6aae;
          }
        }
      }
    }

    tbody.p-datatable-tbody {
      & > tr {
        & > td {
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          padding: 25px 30px;
        }
      }
    }
  }

  // override some prime style
  .p-sortable-column {
    &:not(.p-highlight),
    &.p-highlight {
      &:not(.p-sortable-disabled):hover {
        // background-color: transparent;
        // color: #0a6aae;

        // .p-sortable-column-icon {
        //   color: #0a6aae;
        // }
      }
    }
  }
}
.p-field {
  label {
    color: #252631;
    font-family: "Satoshi", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 13px;
  }

  input,
  select,
  textarea {
    border: 1px solid #dce3e9;
    border-radius: 4px;
    background-color: #ffffff;
    font-family: "Satoshi", sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;
    @include placeholder-color(#98a9bc);
    height: auto;
    padding: 16px 15px;
  }

  // input {
  //   height: auto;
  //   padding: 16px 15px;
  // }

  .p-multiselect {
    border: 1px solid #dce3e9;
    border-radius: 4px;
    background-color: #ffffff;

    .p-multiselect-trigger {
      .p-multiselect-trigger-icon {
        color: #9bb1c7;
      }
    }

    &.p-multiselect-chip {
      // padding-top: 0.25rem;
      // padding-bottom: 0.25px;

      .p-multiselect-label {
        .p-multiselect-token {
          padding-right: 1rem;
          padding-left: 1rem;
          background-color: #f2f4f6;

          .p-multiselect-token-label {
            // font-size: 12px;
          }
        }
      }
    }

    .p-multiselect-label {
      border-radius: 4px;
      background-color: #ffffff;
      font-family: "Satoshi", sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      @include placeholder-color(#98a9bc);
      padding: 13px 15px;
    }
  }

  .p-dropdown {
    border-color: #ced4da !important;

    .p-dropdown-label {
      // border: 1px solid #DCE3E9;
      border-radius: 4px;
      background-color: #ffffff;
      font-family: "Satoshi", sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      @include placeholder-color(#98a9bc);
      padding: 13px 15px;
    }

    &:not(.p-disabled):hover {
      border-color: #ced4da;
      outline: none !important;
    }
  }

  &.text-with-select {
    display: flex;
    flex-wrap: wrap;

    label {
      width: 100%;
    }

    .p-inputtext {
      width: 63%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &:hover,
      &:enabled:focus {
        border-color: #dce3e9;
      }
    }

    .p-dropdown {
      width: 37%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;

      .p-dropdown-label {
        border: none;
      }
    }
  }
}
.p-scrollpanel {
  .p-scrollpanel-wrapper {
    border-right: 10px solid #e8ecef;
  }

  .p-scrollpanel-bar {
    background-color: rgba(28, 28, 28, 0.11);
    opacity: 1;
    transition: background-color 0.3s;

    &:hover {
      background-color: rgba(28, 28, 28, 0.21);
    }
  }
}
.p-tabview {
  ul.p-tabview-nav {
    border-bottom: none;
    background-color: transparent;

    li {
      a.p-tabview-nav-link {
        background-color: transparent;
        padding-bottom: 0;
        padding-top: 0;
        color: #778ca2;
        font-family: "Satoshi", sans-serif;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 22px;
        font-weight: 300;
        box-shadow: none !important;

        .p-badge {
          border-radius: 3px;
          background-color: #f2f4f6;
          margin-left: 6px;
          color: #98a9bc;
          font-family: "Satoshi", sans-serif;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 21px;
          text-align: center;
          font-weight: 400;
          height: auto;
        }
      }

      &.p-tabview-selected {
        a.p-tabview-nav-link {
          color: #252631;
          font-weight: 400;

          .p-badge {
            background-color: rgba(16, 111, 168, 0.1);
            color: #106fa8;
          }
        }
      }

      &.p-highlight {
        a.p-tabview-nav-link {
          background-color: transparent;
        }
      }

      &:not(.p-highlight):not(.p-disabled):hover {
        a.p-tabview-nav-link {
          background-color: transparent;
        }
      }

      &:first-child {
        a {
          padding-left: 0;
        }
      }
    }
  }

  .p-tabview-panels {
    background-color: transparent;
    padding: 5px 0;
  }

  &.big-blue {
    ul.p-tabview-nav {
      li {
        padding: 0 36px 0 0;

        a.p-tabview-nav-link {
          padding: 0 0 18px;
          border-bottom: 2px solid transparent;
          margin-bottom: 0;
          color: #778ca2;
          font-family: "Satoshi", sans-serif;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
          text-align: center;
          text-transform: uppercase;
        }

        &.p-tabview-selected,
        &:not(.p-highlight):not(.p-disabled):hover {
          a.p-tabview-nav-link {
            border-bottom-color: $primaryBlue;
            color: $primaryBlue;
          }
        }
      }
    }
  }
}
.p-paginator {
  button {
    height: 40px;
    display: flex;
    justify-content: center;
    padding: 12px;
    margin: 0 3px;
    background-color: transparent;

    &.p-paginator-prev,
    &.p-paginator-next {
      color: #778ca2;
      font-family: "Satoshi", sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 17px;

      i {
        font-size: 18px;
        line-height: 21px;
      }
    }

    &.p-paginator-prev {
      margin-right: 12px;

      i {
        margin-right: 6px;
      }
    }

    &.p-paginator-next {
      margin-left: 12px;

      i {
        margin-left: 6px;
      }
    }
  }

  .p-paginator-pages {
    display: flex;

    button.p-paginator-page {
      color: #252631;
      font-family: "Satoshi", sans-serif;
      font-size: 14px;
      letter-spacing: 0.21px;
      line-height: 32px;
      text-align: center;

      &.p-highlight {
        background-color: $primaryBlue;
        color: white;
      }
    }
  }
}
// .p-datatable {
//   table {
//     thead.p-datatable-thead {
//       th {
//         background-color: transparent;
//         color: #98a9bc;
//         font-family: "Satoshi", sans-serif;
//         font-size: 14px;
//         letter-spacing: 0;
//         line-height: 21px;
//         font-weight: 400;
//         border-bottom: 2px solid #f6f9fc;
//         box-shadow: none !important;
//         padding-top: 13px;
//         padding-bottom: 13px;

//         &.id {
//           width: 80px;
//         }

//         &.actions {
//           width: 90px;
//           text-align: right;
//         }

//         &.p-sortable-column {
//           .pi {
//             color: #778ca2 !important;
//             font-size: 13px;
//             line-height: 22px;
//             float: right;

//             &.pi-sort-alt {
//               font-size: 11px;
//             }
//           }

//           &:not(.p-highlight):not(.p-sortable-disabled):hover {
//             // color: #98a9bc;
//             // background-color: transparent;
//           }
//         }

//         &.p-highlight:not(.p-sortable-disabled) {
//           color: #98a9bc;

//           &:hover {
//             color: #98a9bc;

//             .pi {
//               color: #98a9bc;
//             }
//           }
//         }
//       }
//     }

//     tbody.p-datatable-tbody {
//       tr {
//         td {
//           background-color: transparent;
//           color: #252631;
//           font-family: "Satoshi", sans-serif;
//           font-size: 14px;
//           letter-spacing: 0.3px;
//           line-height: 20px;
//           border: none;
//           border-bottom: 1px solid #f2f4f6;

//           &.id {
//             width: 80px;
//           }

//           &.name {
//             font-weight: 500;
//           }

//           &.actions {
//             text-align: right;
//             width: 90px;

//             .las {
//               cursor: pointer;
//               color: #778ca2;
//               font-size: 22px;
//               line-height: 26px;
//               @include scale-1-2-on-hover;

//               &:hover {
//                 color: #252631;
//               }
//             }
//           }
//         }

//         &.p-rowgroup-header {
//           td {
//             .p-rowgroup-header-name {
//               text-transform: uppercase;
//               font-size: 16px;
//               line-height: 22px;
//             }
//           }
//         }

//         &:hover {
//           background-color: #f8fafb;
//         }
//       }
//     }
//   }
// }
.p-overlaypanel {
  &.table-row-menu {
    width: 100px;
  }
}
.p-button {
  background-color: $primaryBlue;
  border-radius: 4px;
  padding: 13px 17px;
  text-transform: uppercase;
  color: white;
  font-family: "Satoshi", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  border: none;
  min-width: 125px;
  justify-content: center;

  &.p-button-text {
    color: $primaryBlue;
    font-family: "Satoshi", sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
    padding: 0;
    text-transform: uppercase;
    min-width: unset;

    .p-button-icon {
      color: $primaryBlue;
      font-size: 16px;
      line-height: 18px;
      margin-right: 6px;
    }

    &.p-button-secondary {
      color: #778ca2;

      span {
        display: block;

        &.p-button-label {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 12px;
          position: relative;
          top: 1px;
        }

        &.p-button-icon {
          color: #778ca2;
        }
      }
    }

    &.link-recommendation {
      color: $primaryPurple;

      .p-button-icon {
        color: $primaryPurple;
      }

      &:enabled:hover {
        color: lighten($primaryPurple, 15);
      }
    }

    &.link-activity {
      color: $primaryGreen;

      .p-button-icon {
        color: $primaryGreen;
      }

      &:enabled:hover {
        color: lighten($primaryGreen, 15);
      }
    }

    &.star {
      .p-button-icon {
        &.las {
          color: #ffab2b !important;
        }
      }
    }
  }

  &:not(.p-button-text):not(.p-button-secondary):hover {
    background-color: darken($color: $primaryBlue, $amount: 5);
  }

  &.p-button-secondary {
    background-color: #f2f4f6;
    border: none;
    color: #778ca2;
    font-family: "Satoshi", sans-serif;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 17px;

    &:enabled:hover {
      background-color: darken($color: #f2f4f6, $amount: 5);
      color: #778ca2;
    }

    &.p-button-icon-only {
      width: auto;

      .p-button-icon {
        margin-right: 0;
      }
    }
  }
}
.p-checkbox {
  .p-checkbox-box {
    border-color: #e8ecef;
    background-color: #e8ecef;

    &.p-highlight {
      background-color: $primaryBlue !important;
      border-color: $primaryBlue !important;

      .p-checkbox-icon {
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  &:not(.p-checkbox-disabled) .p-checkbox-box {
    &:hover {
      border-color: #e8ecef;
    }

    &.p-focus {
      box-shadow: none;
      border-color: #e8ecef;

      &.p-highlight {
        background-color: $primaryBlue;
        border-color: $primaryBlue;
      }
    }
  }
}
.p-editor-container {
  display: flex;
  flex-direction: column-reverse;

  .p-editor-toolbar.ql-snow {
    border: none;
    background-color: #f8fafb;

    .ql-stroke {
      stroke: #98a9bc;
    }

    .ql-picker {
      .ql-picker-label {
        color: #98a9bc;
        font-weight: normal;
      }
    }
  }

  .p-editor-content.ql-snow {
    border: none;

    .ql-editor {
      background-color: #f8fafb;
      font-family: "Satoshi", sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.3px;
      line-height: 17px;
      font-style: normal;

      &.ql-blank {
        &:before {
          color: #98a9bc;
          font-family: "Satoshi", sans-serif;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.3px;
          line-height: 17px;
          font-style: normal;
        }
      }
    }
  }
}
.p-calendar {
  width: auto;

  input {
    border-right: 0px;

    &:enabled:hover {
      border-color: #dce3e9;
    }
  }

  button.p-datepicker-trigger {
    background-color: transparent;
    color: #778ca2;
    border: 1px solid #dce3e9;
    border-left: 0px;

    &.p-button-icon-only {
      min-width: unset;
      width: 50px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.nrtd-list {
  .nrtd-list-header {
    padding: 0 0 10px;
    display: flex;
    justify-content: space-between;

    .dataview-list-sort-buttons {
      display: flex;
      align-items: center;

      .nrtd-filter-select,
      .p-button {
        margin-left: 28px;
      }
    }

    .dataview-number-of-items {
      color: #252631;
      font-family: "Satoshi", sans-serif;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
  }

  .p-dataview-header {
    padding: 0 !important;
    border-top: none !important;
  }

  .p-dataview-content {
    padding: 2px 25px;
    background-color: white;
  }

  .nrtd-list-item {
    padding: 23px 0;
    border-bottom: 1px solid #e8ecef;
    display: flex;
    justify-content: space-between;

    .nrtd-list-item--selection {
      margin-right: 12px;
    }

    .nrtd-list-item--content {
      display: flex;
      flex-wrap: wrap;
      flex: 1;

      title {
        width: 100%;
        display: block;
        color: #252631;
        font-family: "Satoshi", sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        margin-bottom: 11px;
        transition: all 400ms;
      }

      .property-field {
        .property-field-value {
          &.implementation-status {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }
        }
      }
    }

    .nrtd-list-item--links {
      flex: 0 0 200px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 16px;

      .p-button {
        & + .p-button {
          margin-top: 11px;
        }
      }
    }

    .nrtd-list-item--star {
      flex: 0 0 35px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 16px;
    }
  }
}
.p-avatar {
  width: 36px;
  height: 36px;
  background-color: #f2f4f6;

  .p-avatar-text {
    color: #98a9bc;
    font-family: "Satoshi", sans-serif;
    font-size: 12.6px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    text-align: center;
  }
}
.p-multiselect-panel {
  max-width: 550px;

  .p-multiselect-item {
    white-space: normal;
  }
}
.p-fileupload {
  .p-button {
    min-width: unset;
    padding: 0;
    width: 35px !important;
    height: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    .p-button-icon {
      margin-right: 0;
    }

    .p-button-label {
      display: none;
    }
  }
}

// general components
.filter-panel {
  background-color: #e8ecef;
  border-radius: 4px;
  padding: 20px;

  .p-formgrid.p-grid {
    justify-content: space-between;

    .p-field.p-col {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      label {
        font-size: 13px;
      }

      .p-multiselect {
        width: 100%;
        height: 52px;

        .p-multiselect-label-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      .p-inputtext {
        height: 52px;
        width: 100%;
      }

      &.button-col {
        flex-direction: row;
        align-items: flex-end;

        a,
        .p-button {
          margin-left: 20px;
        }

        .p-button + a {
          margin-bottom: 12px;
        }

        // flex-direction: row;
        // flex-grow: 1.5;
        // align-items: flex-end;

        // button {
        //     &:first-child {
        //         @include gray-button();
        //         font-weight: 500;
        //         padding-left: 8px;
        //         padding-right: 8px;
        //     }

        //     &:last-child {
        //         margin-left: 10px;
        //         @include blue-button();
        //         font-weight: 500;
        //     }
        // }
      }
    }
  }

  .button-row {
  }
}
.implementation-status-template {
  display: flex;
  align-items: center;
}
.single-element {
  background-color: #fff;
  padding: 20px 26px;

  .single-element-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #f2f4f6;

    & > div {
      display: flex;
      align-items: flex-start;
      margin-bottom: -2px;

      .single-element-tabs {
        margin-left: 20px;
        display: flex;

        .p-button.p-button-text {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 17px;
          padding: 0 9px 18px;
          border-radius: 0;
          border-bottom: 2px solid $primaryBlue;

          &.p-button-secondary {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            border-bottom-color: transparent;

            span.p-button-label {
              top: 0;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 17px;
            }
          }

          & + .p-button {
            margin-left: 15px;
          }
        }
      }

      .action-menu {
        margin-left: 4px;

        .p-dropdown-trigger .la-ellipsis-h {
          line-height: 20px;
        }
      }
    }
  }

  .single-element-content {
    display: flex;
    justify-content: space-between;
    padding-top: 23px;

    .single-element-tab-content {
      width: 70%;
    }

    .single-element-sidepanel {
      width: 21%;
      display: flex;
      flex-direction: column;

      .p-button {
        &:first-child {
          display: flex;
          justify-content: center;

          .p-button-label {
            flex: unset;
          }
        }
      }

      .single-element-sidepanel-list {
        margin-top: 30px;

        title {
          display: block;
          color: #778ca2;
          font-family: "Satoshi", sans-serif;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 17px;
          margin-bottom: 12px;
        }

        .sidepanel-list-item {
          position: relative;

          .remove-button {
            position: absolute;
            top: 0;
            right: 0;
            width: 73px;
            height: 100%;
            display: none;
            justify-content: flex-end;
            padding-right: 14px;
            align-items: center;
            z-index: 2;
            cursor: pointer;

            i {
              font-size: 16px;
              letter-spacing: 0;
              line-height: 18px;
              text-align: center;
            }
          }

          &:hover {
            .remove-button {
              display: flex;
            }
          }

          & + .sidepanel-list-item {
            margin-top: 7px;
          }
        }

        &.projects {
          .sidepanel-list-item {
            .remove-button {
              background: linear-gradient(
                90deg,
                rgba(236, 244, 248, 0) 0%,
                #ecf4f8 100%
              );

              i {
                color: #106fa8;
              }
            }
          }
        }

        &.institutions {
          .sidepanel-list-item {
            .item-content {
              span {
                color: #252631;
                font-family: "Satoshi", sans-serif;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 14px;
                text-transform: uppercase;
              }
            }
          }
        }

        &.recommendations {
          .sidepanel-list-item {
            margin-top: 15px;

            .item-content {
              display: flex;
              align-items: center;

              span {
                max-height: 42px;
                overflow: hidden;
                color: #252631;
                font-family: "Satoshi", sans-serif;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 14px;
                text-transform: uppercase;
              }

              i {
                color: $primaryPurple;
                background-color: #f2e8f8;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 30px;
                height: 30px;
                margin-right: 10px;
              }
            }

            .remove-button {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0.5) 0%,
                #ffffff 100%
              );

              i {
                color: #903fc2;
                font-size: 20px;
              }
            }
          }
        }

        &.activities {
          .sidepanel-list-item {
            margin-top: 15px;

            .item-content {
              display: flex;
              align-items: center;

              span {
                max-height: 42px;
                overflow: hidden;
                color: #252631;
                font-family: "Satoshi", sans-serif;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 14px;
                text-transform: uppercase;
              }

              i {
                color: $primaryGreen;
                background-color: rgba(109, 210, 48, 0.13);
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 30px;
                height: 30px;
                margin-right: 10px;
              }
            }

            .remove-button {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0.5) 0%,
                #ffffff 100%
              );

              i {
                color: $primaryGreen;
                font-size: 20px;
              }
            }
          }
        }

        &.attachments {
          .sidepanel-list-item {
            margin-top: 10px;

            .item-content {
              display: flex;
              align-items: center;
              text-decoration: none;

              span {
                max-height: 42px;
                overflow: hidden;
                color: #252631;
                font-family: "Satoshi", sans-serif;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 14px;
                text-transform: uppercase;
              }

              i {
                color: $primaryBlue;
                background-color: #e2eef5;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 30px;
                height: 30px;
                margin-right: 10px;
              }
            }

            .remove-button {
              background: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0.5) 0%,
                #ffffff 100%
              );

              i {
                color: $primaryBlue;
                font-size: 20px;
              }
            }
          }
        }

        .p-button {
          margin-top: 17px;
        }

        title + button.p-button {
          margin-top: 0;
        }
      }

      .p-button.create-activity {
        margin-top: 20px;
      }
    }

    .details-content {
      .inline-edit-block {
        color: #252631;
        font-family: "Satoshi", sans-serif;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 21px;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &.description {
          color: #778ca2;
          margin-top: 12px;
          font-weight: normal;
        }

        .p-button.p-button-text {
          flex: 0 0 30px;
          top: 2px;
          margin-left: 20px;

          .p-button-icon {
            color: #778ca2;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
          }

          &.star {
            margin-left: 0;
          }
        }
      }
    }
  }
}
.__react_component_tooltip.nrtd-tooltip {
  z-index: 1102;

  &.type-light {
    background-color: #eaeaea;
    border-radius: 4px;
    box-shadow: 0 2px 15px 0 rgba(211, 211, 211, 0.5);
    font-family: "Satoshi" sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-transform: unset;
  }
}
.project-implemented-infos {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .implemented-info {
    width: 110px;

    div {
      color: #252631;
      font-family: "Satoshi" sans-serif;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 19px;
      text-align: center;

      span {
        &:first-child {
          font-weight: normal;
          margin-right: 3px;
        }

        &:last-child {
          margin-left: 3px;
        }
      }
    }

    p {
      color: #98a9bc;
      font-family: "Satoshi" sans-serif;
      font-size: 14px;
      letter-spacing: -0.5px;
      line-height: 21px;
      text-align: center;
      margin: 5px 0 0;
    }
  }
}
.project-items-tabs {
  display: flex;

  .p-button {
    transition: 0.2s all;
    text-transform: unset;
    font-weight: normal;
    display: flex;
    align-items: stretch;

    .p-badge {
      font-family: "Satoshi" sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 21px;
      text-align: center;
      border-radius: 3px;
      font-weight: normal;
      height: unset;
      border-radius: 3px;
    }

    &:not(.p-button-secondary) {
      font-family: "Satoshi" sans-serif;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 22px;

      .p-badge {
        color: #106fa8;
        background-color: rgba(16, 111, 168, 0.1);
      }
    }

    &.p-button-secondary {
      color: #778ca2;
      font-family: "Satoshi" sans-serif;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 22px;

      span.p-button-label {
        color: #778ca2;
        font-family: "Satoshi" sans-serif;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 22px;
        text-transform: unset;
        font-weight: normal;
        top: 0;
      }

      .p-badge {
        background-color: #f2f4f6;
        color: #98a9bc;
      }
    }

    & + .p-button {
      margin-left: 25px;
    }
  }
}
.project-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.selected {
    border-width: 2px;
    border-style: solid;
  }

  &.blue {
    border-color: #106fa8;
    background-color: #d8e8f3;

    i {
      color: #106fa8;
    }
  }

  &.green {
    border-color: #53bb14;
    background-color: #dff5d2;

    i {
      color: #53bb14;
    }
  }

  &.yellow {
    border-color: #ffab2b;
    background-color: #ffedd0;

    i {
      color: #ffab2b;
    }
  }

  &.red {
    border-color: #fe4d55;
    background-color: #ffd8da;

    i {
      color: #fe4d55;
    }
  }
}
.status-bars {
  display: flex;
  align-items: stretch;
  width: auto;
  width: 16px;
  height: 12px;
  margin-right: 7px;

  span {
    width: 4px;
    border-radius: 1px;
    background-color: #dce3e9;

    & + span {
      margin-left: 2px;
    }
  }

  &.completed {
    span {
      background-color: $primaryGreen;
    }
  }

  &.in-progress {
    span {
      &:first-child,
      &:nth-child(2) {
        background-color: $primaryOrange;
      }
    }
  }

  &.not-started {
    span {
      &:first-child {
        background-color: $primaryRed;
      }
    }
  }
}
.property-field {
  .property-field-title {
    color: #98a9bc;
    font-family: "Satoshi" sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 2px;
  }

  .property-field-value {
    color: #252631;
    font-family: "Satoshi" sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;

    &.list {
      transition: 300ms all;
      margin-top: 5px;

      ul {
        margin: 0;
        padding-left: 27px;

        li {
          color: #252631;
          font-family: "Satoshi" sans-serif;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
  }
}

// misc
.project-name-item {
  border-radius: 4px;
  background-color: rgba(16, 110, 168, 0.08);
  padding: 10px 13px;
  color: #106fa8;
  font-family: "Satoshi", sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 17px;
}
