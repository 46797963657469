.statistics-pie-chart {
  position: relative;

  .statistics-chart--title-row {
    text-align: center;
    margin-bottom: 20px;

    .statistics-chart--title {
      color: #252631;
      font-family: "Satoshi" sans-serif;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      text-transform: uppercase;
    }
  }

  .p-chart {
    margin-left: auto;
    margin-right: auto;
  }
}
