$primaryBlue: #106fa8;
$darkBlue: #0e4c72;
$lightBlue: #8ab9da;
$primaryOrange: #ffab2b;
$primaryGreen: #6dd230;
$primaryPurple: #903fc2;
$primaryRed: #fe4d55;

@mixin scale-1-2-on-hover() {
  transition: all 1s;

  &:hover {
    transform: scale(1.2);
  }
}
@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
}
