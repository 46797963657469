@import "../../base";

.page-wrapper.recommendations {
  .recommendations-filter-panel {
    // margin-top: 20px;
  }

  .project-recommendation-list {
    margin-top: 20px;
  }
}
