@import "../../../base";

.recommendations-filter-panel {
  .p-formgrid.p-grid {
    flex-wrap: nowrap;
    
    .p-field.p-col {
      flex: 0 0 25%;

      .p-multiselect {
        max-width: 270px;
      }
      
      &.button-col {
        flex: 0 0 100%;
        margin-bottom: 0;
      }
    }
  }

  &.extended {
    .p-formgrid.p-grid {
      .p-field.p-col {
        flex: 0 0 33.33%;

        .p-multiselect {
          max-width: 344px;
        }

        &.button-col {
          flex: 0 0 33.33%;
          margin-bottom: 20px;

          .p-button {
            width: auto;
          }
        }
      }
    }
  }
}
