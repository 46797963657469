@import "../../../base";

.activity-list {
  .p-dataview {
    .p-dataview-content {
      .nrtd-list-item {
        .nrtd-list-item--content {
          .property-field {
            width: 50%;
            max-width: 250px;
            
            & + .property-field {
              margin-left: 15px;
            }
          }

          &.clickable {
            cursor: pointer;
            
            &:hover {
              title {
                color: $primaryBlue;
              }
            }
          }
        }
      }
    }
  }
}

.filter-panel + .activity-list {
  margin-top: 16px;
}