@import "../../../base";

.page-wrapper.project {
  .single-element.project {
    background-color: white;
    padding: 20px 26px;
    margin-top: 30px;
    margin-bottom: 30px;

    .single-element-content {
      .single-element-tab-content {
        width: 55%;
      }

      .single-element-sidepanel {
        width: 40%;

        .single-element-sidepanel-list {
          button.p-button {
            text-transform: unset;
            font-family: "Satoshi" sans-serif;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
          }
        }
      }
    }
  }

  .project-items {
    .project-items-top-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .project-items-specific-links {
        display: flex;
        align-items: center;

        .link-activity {
          margin-right: 30px;
        }
      }
    }
  }

  .projects-add-lists {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 45px;

    h2 {
      margin: 16px 0 0;
      color: #252631;
      font-family: "Satoshi" sans-serif;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 24px;
      font-weight: 400;
    }

    p {
      color: #778ca2;
      font-family: "Satoshi" sans-serif;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 21px;
      margin: 6px 0 0;
    }

    button {
      margin-top: 24px;
    }
  }
}
