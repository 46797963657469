@import "../../../../base";

.single-element.project {
  .single-element-sidepanel {
    flex-wrap: wrap;
    align-items: flex-end;
    
    .single-element-sidepanel-list {
      margin-top: 0;
      width: 45%;

      &:first-child {
        height: 100%;
      }

      &:last-child {
        margin-top: 30px;
      }
    }
  }
}