@import "../../base";

.about-page {
  .hero {
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }
}
