@import "../../../base";

.page-wrapper.special-procedures {
  .p-datatable {
    table {
      thead.p-datatable-thead {
        & > tr {
          & > th {
            &:first-child {
              .p-column-header-content {
                width: 180px;

                .p-column-title {
                  max-width: 152px;
                }
              }
            }
          }

          th {
            &.status-of-visit {
              width: 260px;
            }
          }
        }
      }

      tbody.p-datatable-tbody {
        tr {
          td {
            &.status-of-visit {
              a {
                margin-left: 8px;

                &:visited {
                  color: $primaryBlue;
                }
              }
            }
          }
        }
      }
    }
  }
}
