@import "../../../base";

.activities-filter-panel {
  .p-formgrid.p-grid {
    flex-wrap: nowrap;

    .p-col {
      flex: 0 0 33.33%;

      .p-multiselect {
        max-width: 368px;
      }

      &.button-col {
        margin-bottom: 0;
      }
    }

    &:last-child {
      justify-content: flex-end;
    }
  }
}
