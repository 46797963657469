@import "../../../../base";

.single-element.recommendation {
  .single-element-sidepanel {
    .single-element-sidepanel-list {
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
