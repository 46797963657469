@import "../../../base";

.hero {
  position: relative;
  margin-bottom: 50px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .hero__content {
    position: absolute;
    inset: 0 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-family: "Satoshi", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 41px;
      line-height: 120%;
      text-align: center;
      color: #ffffff;
      margin: 0;
    }

    .hero__links {
      display: flex;
      margin-top: 40px;

      a {
        font-family: "Satoshi", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        opacity: 0.6;
        cursor: pointer;

        & + a {
          margin-left: 40px;
        }
      }
    }
  }
}
