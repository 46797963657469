@import "../../base";

.page-wrapper.home {
  .home-hero {
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    align-items: center;

    .home-hero__left {
      width: 77%;
      justify-self: end;

      h1 {
        font-family: "Satoshi", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 53px;
        line-height: 106%;
        color: #0a4a71;
        margin: 0;
      }

      p {
        font-family: "Satoshi", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.8);
        margin: 17px 0 0;
      }

      button {
        margin-top: 23px;
        font-family: "Satoshi", sans-serif;
      }
    }

    .home-hero__right {
      align-self: stretch;

      img {
        object-fit: contain;
        width: 100%;
        max-height: 748px;
      }
    }

    @media (max-width: 1250px) {
      .home-hero__left {
        width: 100%;
        padding-left: 70px;
      }
    }
  }

  .home-events {
    background-position: top;
    background-repeat: no-repeat;
    padding-top: 187px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-family: "Satoshi";
      font-style: normal;
      font-weight: 700;
      font-size: 41px;
      line-height: 120%;
      text-align: center;
      color: #ffffff;
      margin: 0 0 31px;
    }

    p {
      font-family: "Satoshi";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: rgba(255, 255, 255, 0.8);
      max-width: 660px;
      margin: 0 auto;

      & + p {
        margin-top: 24px;
      }
    }

    .events-list {
      max-width: 1080px;
      width: 100%;
      margin: 60px auto 0;

      h3 {
        font-family: "Satoshi";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 34px;
        text-align: center;
        color: #8bbfde;
        margin-bottom: 26px;
      }

      .search-wrapper {
        float: right;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        border: none;
        transition: all 200ms;
        padding: 9px 10px;
        transition: all 200ms;
        width: 100px;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-top: -64px;

        i {
          font-size: 15px;
          line-height: 149%;
          color: #8bbfde;
          margin-right: 8px;
        }

        input {
          background-color: transparent;
          border: none;
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #8bbfde;
          @include placeholder-color(#8bbfde);
          outline: none !important;
        }

        &.full-width {
          width: 200px;
        }
      }

      .p-datatable {
      }
    }

    button.blue-button {
      margin-top: 32px;
    }
  }

  .home-human-rights {
    margin-top: 90px;

    h2 {
      font-family: "Satoshi";
      font-style: normal;
      font-weight: 700;
      font-size: 41px;
      line-height: 120%;
      text-align: center;
      margin: 0 auto 16px;
      max-width: 608px;
      width: 100%;
    }

    p {
      margin: 16px 0 0;
      font-family: "Satoshi";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: rgba(0, 0, 0, 0.8);
      margin: 0 auto;
      max-width: 640px;
      width: 100%;
    }

    .home-cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 15px;
      margin: 38px auto 0;

      .card {
        border-radius: 3px;
        display: flex;
        align-items: flex-end;
        padding: 32px 33px;
        height: 520px;
        position: relative;

        h3 {
          font-family: "Satoshi";
          font-style: normal;
          font-weight: 700;
          font-size: 25px;
          line-height: 34px;
          display: flex;
          align-items: flex-end;
          color: #ffffff;
        }

        .card-cover {
          position: absolute;
          inset: 0;
          overflow-y: auto;
          background-color: white;
          padding: 63px 33px 44px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          transition: 400ms opacity;

          p {
            font-family: "Satoshi";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 150%;
            color: #000000;
            margin: 0 0 16px;
            text-align: left;
            transition: 400ms transform;
          }

          .numbers-list {
            background: rgba(119, 140, 162, 0.05);
            border: 1px solid rgba(119, 140, 162, 0.2);
            border-radius: 5px;
            width: 100%;
            transition: 400ms transform;

            .item {
              padding: 11px 19px 21px;
              font-family: "Satoshi";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 150%;
              color: #000000;
              transition: 400ms transform;

              span {
                font-family: "Satoshi";
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 149%;
                color: #0a6aae;
                position: relative;
                top: 3px;
              }

              & + .item {
                border-top: 1px solid rgba(119, 140, 162, 0.2);
              }
            }
          }

          .chevron-link {
            margin-top: 55px;
          }
        }

        &:not(:hover) {
          .card-cover {
            opacity: 0;

            p {
              transform: translateY(60px);
            }

            .numbers-list {
              transform: translateY(60px);

              .item {
                &:nth-child(2n + 1) {
                  transform: translateX(10px);
                }

                &:nth-child(2n) {
                  transform: translateX(-10px);
                }
              }
            }
          }
        }
      }
    }
  }
}
