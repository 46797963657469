@import url("../../../_base.scss");

.nrtd-bars {
  margin: 30px 0 30px;
  width: 100%;

  .bar {
    height: 17px;
    width: 100%;
    display: flex;
    justify-content: flex-start;

    & + .bar {
      margin-top: 10px;
    }
  }
}
